<script>
  export default {
    props: {
      input_type: {
        type: String,
        required: false,
        default: ''
      },
      password_min_length: {
        type: Number,
        default: 8
      },
      confirmation: {
        type: Boolean,
        default: false
      }
    },
    
    computed: {
      eyeIconName () {
        return this.input_type === 'password' ? 'eye_on' : 'eye_off'
      }
    }
  }
</script>

<template>
  <div class='toggle-password-visibility'>
    <svg-icon :name='eyeIconName' size='30px'></svg-icon>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  input[name='password'] {
    padding-right: 3em;
  }
  
  .toggle-password-visibility {
    background-color: $white;
    position: absolute;
    top: 2px;
    right: 0;
    width: 3em;
    height: rem-calc(46);
    cursor: pointer;
    
    &.hide {
      display: none;
    }
    
    .svg-icon {
      position: absolute;
      top: 50% !important;
      right: rem-calc(10) !important;
      transform: translateY(-50%);
      opacity: 0.55;
      
      svg path {
        fill: $dark-gray;
      }
    }
  }
  
  .mq_content__wrapper,
  .dark-inputs {
    .toggle-password-visibility {
      background-color: $nearly-white;
      
      .svg-icon {
        opacity: 1;
        
        svg path {
          fill: $medium-gray;
        }
      }
    }
  }
</style>