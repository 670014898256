import kebabCase  from 'lodash/kebabCase'
import ToastContainer from './libs/toasts/components/ToastContainer'
import ToastService from './libs/toasts/services/toastService'
import { installToastServiceBus } from './libs/toasts/services/toastServiceBus'
import './styles/moqo.scss'
import locales from './locales'
import MqUtils from './mixins/MqUtils'

const requireMoqoCmponents= require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /^(?!.*(?:partials)).*.[A-Z]\w+\.(vue|js)$/
)

const MOQOmponents = {
  install(Vue, options) {
    requireMoqoCmponents.keys().forEach(fileName => {
      // Get component config
      const componentConfig = requireMoqoCmponents(fileName)

      if (componentConfig.default) {

        // Add translations
        componentConfig.default['i18n'] = locales

        // Get the defined components name or create one
        const componentName = componentConfig.default.name || kebabCase(
          // Strip the leading `'./` and extension from the filename
          fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        )

        // Register component globally
        Vue.component(
          componentName,
          // Look for the component options on `.default`, which will
          // exist if the component was exported with `export default`,
          // otherwise fall back to module's root.
          componentConfig.default || componentConfig
        )
      }
    })

    Vue.mixin(MqUtils)

    installToastServiceBus(Vue)

    Vue.component('ToastContainer', ToastContainer)

    Vue.prototype.$toast = {
      add: ToastService.pop,
      remove: ToastService.remove
    }
  }
};

export default MOQOmponents;
