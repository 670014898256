export default {
  props: {
    form: {
      type: Object,
      required: false,
      default: () => null
    },
    field: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    as: {
      type: String,
      required: false,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    permanent_hint: {
      type: Boolean,
      required: false,
      default: false
    },
    standalone: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: ''
    },
    with_info: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fieldId () {
      return `${this.field}_${Math.random().toString(36).slice(2)}`
    },
    showError () {
      return !!this.fieldError && !this.form.inputOptions.viewMode
    },
    fieldError () {
      if (this.error) return [this.error]
      if (this.form && this.form.validation[this.field]?.length) return this.form.validation[this.field]
      return null
    },
    inputWrapperClass () {
      return [
        `column ${this.size} mq-input-container`,
        {'required' : this.required},
        {'error': this.showError}
      ]
    },
    floatLabelClass () {
      return [
        'has-float-label',
        {'disable-floating-label': (this.inputPlaceholder !== ' ')}
      ]
    },
    inputValue () {
      return this.form?.record[this.field] !== 'null' ? this.form?.record[this.field] : null
    },
    inputLabel () {
      if (this.label && this.label !== '') return this.label
      if (this.form) return this.$t(`form.label.${this.form.name}.${this.field}`)
      return null
    },
    inputPlaceholder () {
      if (this.placeholder && this.placeholder !== '') return this.placeholder
      return ' '
    },
    inputViewMode () {
      if (this.disabled !== null) return this.disabled
      if(this.form && this.form.inputOptions) return this.form.inputOptions.viewMode
      return false
    },
    options () {
      return {
        disabled: this.inputViewMode,
        placeholder: this.inputPlaceholder,
        required: this.required,
        id: this.fieldId,
        class: 'mq-input',
        type: this.as,
        name: this.field
      }
    }
  },
  beforeMount () {
    this.createInputStyle()
  },
  methods: {
    setInputValidation (value) {
      if (value !== null) value = [].concat(value)
      this.$set(this.form.validation, this.field, value)
    },
    createInputStyle () {
      const style = `
          input.mq-input:focus,
          textarea.mq-input:focus,
          select.mq-select:focus {
            border-color: ${this.primaryColor};
          }
        `
      this.createCustomStyle(style)
    }
  }
}