<script>
  export default {
    props: {
      icon: {
        type: String,
        required: false,
        default: null
      },
      icon_color: {
        type: String,
        required: false,
        default: '#FFF'
      }
    }
  }
</script>


<template>
  <div :class="['mq-panel', {'mq-panel--with-icon': icon}]">
    <div v-if='icon' class='icon-container'>
      <svg-icon :name='icon' :fill='icon_color' size='40px'></svg-icon>
    </div>
    <div class='content-container'>
      <slot></slot>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .mq-panel{
    padding: rem-calc(20) rem-calc(30);

    &.gray,
    &.grey{
      background: $very-light-gray;
    }

    &.white{
      background: $white;
    }

    &.red,
    &.alert{
      background: $alert-color;
      color: $white;
    }


    &--with-icon{
      display: table-row;
      padding-left: 0;

      .icon-container {
        display: table-cell;
        vertical-align: middle;
        padding: 0 rem-calc(20);
        height: 100%;
      }

      .content-container {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
</style>