<script>
  import { rolePathPrefix } from '@/current_user'
  import stripeVueLoader from '@/lib/stripe_vue_loader'
  import Resource from '@/resources/resources'
  import StripeCard from './stripe_card'
  import StripeSepa from './stripe_sepa'
  import StripeSepaSofort from './stripe_sepa_sofort'
  import Paypal from './paypal'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    components: {
      StripeCard,
      StripeSepa,
      StripeSepaSofort,
      Paypal,
    },
    props: {
      disallow_sepa_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      allow_paypal_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      allow_sepa_sofort_setup: {
        type: Boolean,
        required: false,
        default: false
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      allow_submit: {
        type: Boolean,
        required: false,
        default: true
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      },
      payment_disclaimer: {
        type: String,
        required: true
      },
      skip_payin_source_saving: {
        type: Boolean,
        required: false,
        default: false
      },
      stripe_key: {
        type: String,
        required: true
      },
      stripe_secret: {
        type: String,
        required: true
      },
      owner_name: {
        type: String,
        required: false,
        default: ''
      },
      owner_email: {
        type: String,
        required: false,
        default: ''
      },
      create_with_type: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        payinSources: Resource({ name: 'payin_sources', path: `${rolePathPrefix()}/api/payin_sources`, skipFetching: true, singularResource: true, persisted: true, wrapped: false }),
        is_loading: false,
        stripeLoaded: false
      }
    },
    created () {
      this.payinSources.$on('error', () => {
        window.setTimeout(() => {
          this.is_loading = false
          this.$emit('payment-method-creating', {loading: false})
        }, 500)
      })

      this.payinSources.$once('receive', () => {
        window.setTimeout(() => {
          this.$emit('payment-method-created')
          this.is_loading = false
        }, 500)
      })

      this.payinSources.$once('validation', () => {
        window.setTimeout(() => {
          this.is_loading = false
          this.$emit('payment-method-creating', {loading: false})
        }, 500)
      })
    },
    mounted() {
      if (this.stripe_key) {
        stripeVueLoader(this.stripe_key, () => {
          this.stripeLoaded = true
        })
      }
    },
    computed: {
      options () {
        return {
          backend_errors: this.payinSources.errors,
          owner_name: this.owner_name,
          owner_email: this.owner_email,
          buttons_config: this.buttons_config,
          allow_submit: this.allow_submit,
          stripe_secret: this.stripe_secret,
          event_bus: this.event_bus,
          payment_disclaimer: this.payment_disclaimer,
          is_loading: this.is_loading,
        }
      },

      stripeListeners () {
        return {
          'cancel': this.cancel,
          'payment-method-create': this.paymentMethodCreate,
          'payment-method-creating': this.paymentMethodCreating
        }
      }
    },
    methods: {
      paymentMethodCreate(paymentBy, response) {
        this.is_loading = true

        if (!!this.event_bus && this.skip_payin_source_saving) {
          this.event_bus.$emit('payment-method-created', paymentBy, response)
          return
        }

        this.$emit('payment-method-create', paymentBy, response)
      },
      paymentMethodCreating (stripeElementEvent) {
        this.$emit('payment-method-creating', stripeElementEvent)
      },
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <mq-tabs v-if='stripeLoaded && !create_with_type' @tab-changed="$emit('tab-changed', $event)">
    <mq-tab :name="$t('payment.label.credit_card')" :selected='true'>
      <stripe-card v-bind='options' v-on='stripeListeners'></stripe-card>
    </mq-tab>
    <mq-tab v-if='!disallow_sepa_payments' name='SEPA'>
      <stripe-sepa v-bind='options' v-on='stripeListeners'></stripe-sepa>
    </mq-tab>
    <mq-tab v-if='allow_sepa_sofort_setup' name='SEPA'>
      <stripe-sepa-sofort v-bind='options' v-on='stripeListeners'></stripe-sepa-sofort>
    </mq-tab>
    <mq-tab v-if="allow_paypal_payments" name='PayPal'>
      <paypal v-bind='options' @cancel='cancel'></paypal>
    </mq-tab>
  </mq-tabs>
  <stripe-card v-else-if='stripeLoaded && create_with_type == "card"' v-bind='options' v-on='stripeListeners'></stripe-card>
  <stripe-sepa v-else-if='stripeLoaded && !disallow_sepa_payments && create_with_type == "sepa"' v-bind='options' v-on='stripeListeners'></stripe-sepa>
  <stripe-sepa-sofort v-else-if='stripeLoaded && allow_sepa_sofort_setup && create_with_type == "sofort"' v-bind='options' v-on='stripeListeners'></stripe-sepa-sofort>
  <paypal v-else-if='allow_paypal_payments && create_with_type == "paypal"' v-bind='options' v-on='stripeListeners'></paypal>
</template>

<style lang="scss">
  ul.mq_tabs__tab_list li {
    min-width: 0 !important;
  }
</style>
