<script>
  import tinycolor from 'tinycolor2'

  export default {
    props: {
      form: {
        type: Object,
        required: false,
        default: () => {return null}
      },
      field: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        required: false,
        default: false
      },
      checked: {
        type: Boolean,
        required: false,
        default: false
      },
      error: {
        type: Boolean,
        required: false,
        default: false
      },
      errors: {
        type: Array,
        required: false,
        default: null
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      standalone: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: ''
      }
    },
    data () {
      return {
        standaloneInputVal: this.checked
      }
    },
    beforeMount () {
      this.createCheckboxStyle()
    },
    mounted () {
      // Open all links in the checkbox in a new tab
      Array.from(this.$el.querySelectorAll('a')).forEach(link => { link.target = '_blank' })
    },
    computed: {
      fieldId () {
        return `${this.field}_${Math.random().toString(36).slice(2)}`
      },
      showError () {
        if (this.form) return (!!this.formErrors && !this.form.inputOptions.viewMode)
        return !!this.inputErrors || this.error
      },
      formErrors () {
        if (this.form) {
          let errorsArray = this.form.validation[this.field]
          return (errorsArray?.length) ? errorsArray.join(' ') : null
        }
        return null
      },
      inputErrors () {
        return (this.errors) ? this.errors.join(' ') : this.formErrors
      },
      inputWrapperClass () {
        return [
          'mq-input-wrapper',
          `column ${this.size}`,
          {'required' : this.required},
          {'error': this.showError}
        ]
      },
      inputValue () {
        if (!this.form?.record) return null
        return this.form.record[this.field] !== 'null' ? this.form.record[this.field] : null
      },
      inputLabel () {
        return this.label ? this.label : this.$t(`form.label.${this.form.name}.${this.field}`)
      },
      inputViewMode () {
        if(this.form && this.form.inputOptions) return this.form.inputOptions.viewMode
        return this.disabled
      },
      options () {
        return {
          class: 'mq-input--checkbox',
          value: this.value,
          disabled: this.inputViewMode,
          required: this.required,
          id: this.fieldId,
          name: this.field
        }
      }
    },
    watch: {
      checked: function (newValue, oldValue) {
        if (this.standalone) {
          this.standaloneInputVal = newValue
          this.$emit('input', newValue)
        }
      }
    },
    methods: {
      inputChange () {
        this.$emit('input', this.standaloneInputVal)
      },
      createCheckboxStyle () {
        const lighterSecondaryColor = tinycolor(this.secondaryColor).desaturate(33).darken(0).toString()

        const linkStyle = `
          .mq-input--checkbox:checked + label:before,
          .mq-input--checkbox:checked:hover + label:before {
            background-color: ${ this.secondaryColor } !important;
          }
          .mq-input--checkbox:focus + label:before,
          .mq-input--checkbox:hover + label:before {
            border-color: ${ lighterSecondaryColor } !important;
          }
        `

        this.createCustomStyle(linkStyle)
      },
    }
  }
</script>


<template>
  <div v-if='standalone' :class="inputWrapperClass">
    <input type="checkbox" v-model='standaloneInputVal' @change="inputChange()" v-bind='options' />

    <label v-if='!!$slots.default' :for='fieldId'>
      <slot></slot>
    </label>
    <label v-else :for='fieldId' v-html='inputLabel'></label>

    <div v-if='showError && inputErrors' class='errors'>
      <p class='alert-color small'>{{ inputErrors }}</p>
    </div>
  </div>

  <div v-else-if="form.record && field" :class="inputWrapperClass">
    <input type="checkbox" v-model='form.record[field]' v-bind='options' />

    <label v-if='!!$slots.default' :for='fieldId'>
      <slot></slot>
    </label>
    <label v-else :for='fieldId' v-html='inputLabel'></label>

    <div v-if='showError' class='errors'>
      <p class='alert-color small'>{{ inputErrors }}</p>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .mq-input--checkbox[type='checkbox'] {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: rem-calc(-9999);

      & + label {
        position: relative;
        display: inline-block;
        padding-left: rem-calc(35);
        margin-bottom: 0;
        margin-left: 0;
        font-size: rem-calc(16);
        line-height: 1.5;
        cursor: pointer;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: rem-calc(25);
          height: rem-calc(25);
          content: '';
          background-color: $white;
          border: rem-calc(2) solid $light-gray;
        }

        &:after {
          position: absolute;
          top: rem-calc(3);
          left: rem-calc(5);
          width: rem-calc(15);
          height: rem-calc(18);
          content: '';
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9.7" height="16" viewBox="5.8 127.2 9.7 16"><path fill="white" d="M8.4 143.2l-2.6-6.1 1.3-1.1 1.9 4.6 5.3-13.4 1.3 1-6 15z"/></svg>');
          background-repeat: no-repeat;
          background-size: rem-calc(15) rem-calc(18);
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }

        &:hover:after {
          color: $medium-gray;
        }
      }
    }

    &:checked + label:before {
      border: none;
    }

    &:disabled:checked + label:before,
    &:disabled:not(:checked) + label:before {
      background-color: $very-light-gray;
      border-color: $light-gray;
    }

    &:disabled:checked + label:after {
      color: $light-gray;
    }

    .error &:not(:checked) + label:before {
      border-color: $alert-color !important;
    }

    ~ .errors {
      padding-left: rem-calc(35);
    }
  }
</style>
