<script>
  export default {
    props: {
      items: [Array, Object],
      checkmark: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: '#000'
      }
    },
    computed: {
      listClass () {
        return {
          'mq-list': true,
          'checkmark': this.checkmark
        }
      }
    }
  }
</script>


<template>
  <ul v-if='items' :class="listClass">
    <li v-for='item in items'>
      <svg-icon v-if='checkmark' name="check_simple" size='20px' offset='3px' :fill='color'></svg-icon>
      <span class='item-content' v-html='item'></span>
    </li>
  </ul>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  ul.mq-list {
    padding: 0 0 0 1em;

    li {
      position: relative;
      font-size: rem-calc(15);
      margin-bottom: rem-calc(10);
    }

    &.checkmark {
      padding: 0;
      margin-left: rem-calc(-4);
      list-style: none;

      li {
        span.svg-icon{
          position: absolute;
          top: 0;
          left: 0;
        }
        > span.item-content {
          display: inline-block;
          margin-left: rem-calc(25);
        }
      }
    }
  }
</style>
