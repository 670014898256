<script>
  import locales from '@moqomps/locales'
  import {rolePathPrefix} from '@/current_user'

  export default {
    i18n: locales,

    props: {
      stripe: {
        type: Object,
        default: () => null
      },
      is_loading: {
        type: Boolean,
        required: false,
        default: false
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      }
    },

    paypalUrl: `${rolePathPrefix()}/paypal_payin_source`,

    computed: {
      paypalNotice () {
        return this.event_bus?.$data?.paypal_sign_up_notice ||
               this.$t('payment.sources_create.paypal.disclaimer')
      },

      showButtons () {
        return !this.event_bus?.$data?.paypal_sign_up_notice
      },

      submitButtonLabel () {
        return this.buttons_config?.submit?.label ||
          this.$t('buttons.activate')
      },

      submitButtonClasses () {
        return this.buttons_config?.submit?.classes ||
          'column medium-12 medium-push-12 text-left text-center-for-small-only'
      },

      cancelButtonLabel () {
        return this.buttons_config?.cancel?.label ||
          this.$t('buttons.cancel')
      },

      cancelButtonClasses () {
        return this.buttons_config?.cancel?.classes ||
          'column medium-12 medium-pull-12 text-right text-center-for-small-only'
      }
    },

    methods: {
      submit () {
        this.$emit('submit')
      },

      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <div>
    <p v-html="paypalNotice" class="gutter-bottom" ></p>

    <mq-input-row v-if='showButtons' as='submit'>
      <div :class='submitButtonClasses'>
        <mq-button :color='primaryColor' size='small' :href='$options.paypalUrl'>{{ submitButtonLabel }}</mq-button>
      </div>
      <div :class='cancelButtonClasses'>
        <mq-button class='payment_method_edit_card_cancel' as='ghost' size='small' color='gray' @click.native.prevent='cancel'>{{ cancelButtonLabel }}</mq-button>
      </div>
    </mq-input-row>
  </div>
</template>