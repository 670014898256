<script>
  export default {
    data () {
      return {
        tabs: [],
      }
    },
    created () {
      this.tabs = this.$children
    },
    methods: {
      selectTab (selectedTab) {
        this.tabs.forEach(tab => {
          if ((tab.name === selectedTab.name)){
            if (!tab.isActive) {
              tab.isActive = true
              this.$emit('tab-changed', selectedTab.name)
            }
          } else {
            tab.isActive = false
          }
        })
      },
    }
  }
</script>


<template>
  <div class="mq_tabs">
    <ul class="mq_tabs__tab_list">
      <li v-for="(tab, index) in tabs" :key="index" :class="{'is-active': tab.isActive}" @click="selectTab(tab)">
        {{ tab.name }}
      </li>
    </ul>
    <div class="mq_tabs__tab_content">
      <slot></slot>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  ul.mq_tabs__tab_list {
    width: 100%;
    padding: 0 0 rem-calc(30) 0;
    text-align: center;

    li {
      display: inline-block;
      list-style: none;
      min-width: 8em;
      margin: 0 rem-calc(5);
      padding: rem-calc(15) rem-calc(20) rem-calc(11);
      font-weight: 600;
      font-size: rem-calc(20);
      cursor: pointer;

      @include breakpoint(small only) {
        min-width: 0;
        padding: rem-calc(15) rem-calc(10) rem-calc(11);
      }

      &:hover {
        background-color: $nearly-white;
      }

      .mq_content__wrapper & {
        &:hover {
          background: $very-light-gray;
        }
      }

      &.is-active {
        position: relative;
        background-color: $white;
        box-shadow: 0 rem-calc(10) 0 rem-calc(-5) $light-gray;

        .mq_content__wrapper & {
          background-color: $darker-white;
        }
      }
    }
  }
</style>