<script>
  export default {
    props: {
      icon_name: {
        type: String,
        required: false,
        default: null
      },
      content: {
        type: String,
        required: true
      },
      link_to: {
        type: String,
        required: false,
        default: null
      },
      link_title: {
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>


<template>
  <div class='toast toast--header text-center' :style='{backgroundColor : secondaryColor}'>
    <span v-if='icon_name' class='icon hide-for-small-only'>
      <svg-icon :name='icon_name' size='40px' fill='#FFFFFF'></svg-icon>
    </span>
    <span class='text' v-html='content'></span>
    <span v-if='link_to' class='link'>
      <mq-button :class="`notification_link_to_${link_to}`" size='tiny' color='white' :destination='link_to'>{{ link_title }}</mq-button>
    </span>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .toast {
    &--header {
      color: $white;
      line-height: rem-calc(50);
      vertical-align: middle;

      @include breakpoint(small only) {
        padding-top: rem-calc(5);
        padding-bottom: rem-calc(5);
      }

      .icon {
        margin-right: rem-calc(30);
      }

      .text {
        margin-right: rem-calc(30);
        line-height: 1.2;

        @include breakpoint(small only) {
          line-height: 21px;
          display: block;
          text-align: center;
          margin-right: 0;
          margin-bottom: rem-calc(10px);
        }
      }

      .link {
        position: relative;
        top: rem-calc(-1px);
        display: inline-block;
        line-height: 1;
        vertical-align: middle;

        @include breakpoint(small only) {
          display: block;
        }
      }
    }
  }
</style>