<script>
  export default {
    props: {
      as: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      rowClasses () {
        return [
          'row',
          this.modifierClass
        ]
      },

      modifierClass () {
        return this.as ? `row--${this.as}` : 'row--input'
      }
    }
  }
</script>


<template>
  <div :class='rowClasses'>
    <slot></slot>
  </div>
</template>


<style lang='scss' scoped>
  @import '~@moqomps/style';

  .row--input {
    margin-bottom: rem-calc(20);

    .column:not(:last-child) {
      @include breakpoint(small only) {
        margin-bottom: rem-calc(20);
      }
    }
  }

  .row--submit {
    min-height: rem-calc(65);
    padding-top: rem-calc(20);
  }
</style>