<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isActive: this.selected,
      }
    }
  }
</script>


<template>
  <div v-if="isActive">
    <slot></slot>
  </div>
</template>

