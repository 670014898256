<script>
  import Paypal from './paypal'
  import PayunityCard from './payunity_card'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    components: {
      Paypal,
      PayunityCard
    },
    props: {
      allow_paypal_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      payunity_js_widget_url: {
        type: String,
        requred: true
      },
      payunity_brands: {
        type: String,
        required: false,
        default: ''
      },
      owner_name: {
        type: String,
        required: false,
        default: ''
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      }
    },
    computed: {
      options () {
        return {
          payunity_brands: this.payunity_brands,
          payunity_js_widget_url: this.payunity_js_widget_url,
          owner_name: this.owner_name,
          payment_disclaimer: this.payment_disclaimer,
          buttons_config: this.buttons_config
        }
      }
    },
    methods: {
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <payunity-card v-bind='options' @cancel='cancel'></payunity-card>
</template>

<style lang="scss">
  ul.mq_tabs__tab_list li {
    min-width: 0 !important;
  }
</style>