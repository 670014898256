<script>
  import axios from 'axios';
  import CSRFHeaders from '@/lib/csrf-token';
  import MqInputDefaults from '@moqomps/mixins/MqInputDefaults';
  import _debounce from 'lodash/debounce';

  export default {
    mixins: [MqInputDefaults],
    props: {
      country: String,
    },
    data() {
      return {
        searchQuery: '',
        autocompleteResults: [],
        showResults: false,
        id: this.fieldId,
        class: 'mq-input',
        type: this.as,
        name: this.field,
      };
    },
    methods: {
      terminateAxiosRequest(timeoutMs) {
        const abortController = new AbortController();
        setTimeout(() => abortController.abort(), timeoutMs || 0);

        return abortController.signal;
      },
      handleInput: _debounce(function () {
        if (this.searchQuery.length >= 1) {
          this.fetchAutocompleteResults();
        } else {
          this.autocompleteResults = [];
          this.showResults = false;
        }
      }, 300),

      fetchAutocompleteResults() {
        axios.defaults.headers.common = CSRFHeaders();
        axios
          .get('/autocomplete_places', {
              params: { query: this.searchQuery, country: this.country },
              signal: this.terminateAxiosRequest(5000)
              })
          .then((response) => {
            this.autocompleteResults = response.data.predictions;
            this.showResults = true;
          })
          .catch((error) => {
            console.log('Error:', error);
          });
      },

      selectPlace(result) {
        this.form.record[this.field] = result.terms[0].value;
        this.searchQuery = result.terms[0].value;
        this.showResults = false;
      },

      clearInput() {
        this.searchQuery = '';
        this.autocompleteResults = [];
        this.showResults = false;
      },

      closeResultsOnOutsideClick() {
        this.showResults = false;

        document.removeEventListener('click', this.closeResultsOnOutsideClick);
      },
    },
    watch: {
      searchQuery: function(val){
        this.form.record[this.field] = val;
      },
      showResults(newVal) {
        if (newVal) {
          document.addEventListener('click', this.closeResultsOnOutsideClick);
        } else {
          document.removeEventListener('click', this.closeResultsOnOutsideClick);
        }
      },
    },
  };
</script>


<template>
  <div :class="inputWrapperClass">
    <div :class="floatLabelClass">

      <input v-model="searchQuery" @input="handleInput" v-bind='options'>
      <div v-if="showResults">
        <button v-show="searchQuery" @click.prevent="clearInput" class='clear-btn'>{{ $t('autocomplete.clear') }}</button>
      </div>
      <label v-if='inputLabel' :for='fieldId'>{{ inputLabel }}</label>

      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small' v-html="fieldError.join(' ')"></p>
      </div>

      <ul v-show="showResults" class="autocomplete-dropdown">
        <li v-for="result in autocompleteResults" :key="result.id" @mousedown.prevent="selectPlace(result)">
          {{ result.description }}
        </li>
        <li v-if="autocompleteResults.length === 0">{{ $t('autocomplete.no_results') }}</li>
      </ul>
    </div>

      <a v-if='with_info' @click="$emit('info-event')" class='info-button'>
        <svg-icon name="info" size='40px' fill='#9e9e9e'></svg-icon>
      </a>
  </div>
</template>

<style lang="scss" scoped>
 @import '~@moqomps/style';

.mq-input-container {
  position: relative;
}
.autocomplete-dropdown {
  padding: 0;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.autocomplete-dropdown li {
  padding: 8px;
  cursor: pointer;
  user-select: none;
}
.autocomplete-dropdown li:hover {
  background: rgb(205, 236, 244);
}
.clear-btn {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}
</style>
