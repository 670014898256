<script>
  export default {
    props: {
      title: {
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>


<template>
  <div class='mq-modal__wrapper'>
    <a class='mq-modal__close-button' @click.prevent="$parent.$emit('close')">&times;</a>
    <div class='mq-modal__body'>
      <div class='mq-modal__header' v-if="title" v-text='title'></div>
      <slot></slot>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  // overvriting modal module css style
  .v--modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
  }

  .v--modal {
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 10px !important;
  }

  .mq-modal {
    &__wrapper {
      position: relative;
      background-color: $white;
      box-shadow: 0 rem-calc(20) 0 rem-calc(-10) $light-gray;
    }

    &__body {
      padding: rem-calc(40) rem-calc(30) rem-calc(30);
    }

    &__close-button {
      position: absolute;
      top: rem-calc(20);
      right: rem-calc(20);
      font-weight: 600;
      font-size: rem-calc(30);
      line-height: 0.45;
      cursor: pointer;
    }

    &__header {
      margin-bottom: rem-calc(50);
      font-size: rem-calc(27);
      font-weight: 400;
    }
  }
</style>
