<script>
  import mqStripeInputStyle from '@moqomps/styles/mq-stripe-input'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    props: {
      owner_name: {
        type: String,
        required: false,
        default: ''
      },
      stripe_secret: {
        type: String,
        required: true
      },
      backend_errors: {
        type: Object,
        default: () => {}
      },
      is_loading: {
        type: Boolean,
        default: false
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      allow_submit: {
        type: Boolean,
        required: false,
        default: true
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      },
      payment_disclaimer: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        ownerName: this.owner_name,
        stripeError: null,
        card: null
      }
    },
    computed: {
      showButtons () {
        return !(this.event_bus)
      },
      submitButtonLabel () {
        return this.buttons_config?.submit?.label ||
          this.$t('buttons.save')
      },
      submitButtonClasses () {
        return this.buttons_config?.submit?.classes ||
          'column medium-12 medium-push-12 text-left text-center-for-small-only'
      },
      cancelButtonLabel () {
        return this.buttons_config?.cancel?.label ||
          this.$t('buttons.cancel')
      },
      cancelButtonClasses () {
        return this.buttons_config?.cancel?.classes ||
          'column medium-12 medium-pull-12 text-right text-center-for-small-only'
      },
      errors () {
        if (Object.keys(this.backend_errors).length) {
          this.$emit('payment-method-creating', {loading: false})

          let errorString = ''

          Object.keys(this.backend_errors).forEach(errorKey => {
            errorString += this.backend_errors[errorKey].join('<br />')
          })

          this.stripeError = {
            message: errorString
          }
        }

        return this.stripeError
      }
    },
    created () {
      if (this.event_bus) {
        this.event_bus.$on('submit', this.create)
      }
    },
    mounted () {
      this.card = this.$stripeElements.create('card', mqStripeInputStyle)
      this.card.mount(this.$refs.card)

      this.card.addEventListener('change', () => {
        this.stripeError = null
      })

      this.$el.querySelectorAll('a').forEach(link => { link.target = '_blank' })
    },
    beforeDestroy () {
      if (this.event_bus) {
        this.event_bus.$off('submit', this.create)
      }

      if (this.card && typeof this.card.destroy === 'function') {
        this.card.destroy()
      }
    },
    methods: {
      create () {
        if (!this.allow_submit) {
          if (!!this.event_bus) {
            this.event_bus.$emit('failed-submit')
          }
          return false
        }

        this.$emit('payment-method-creating', {loading: true})

        let extraDetails = {
          payment_method_data: {
            billing_details: {name: this.ownerName}
          }
        }

        this.$stripe.handleCardSetup(this.stripe_secret, this.card, extraDetails).then(response => {
          if (response.setupIntent) {
            this.$emit('payment-method-create', 'card', response.setupIntent)
          }

          if (response.error) {
            this.$emit('payment-method-creating', {loading: false})
            this.stripeError = response.error
          }
        })
      },

      submit () {
        this.$emit('submit')
      },
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <form @submit.prevent='create' class='stripe-elements-card-form'>
    <div class="medium-gutter-bottom">
      <mq-input-row>
        <mq-input standalone field='card_holder' v-model='ownerName' :label="$t('payment.label.credit_card_owner')" size='medium-24'></mq-input>
      </mq-input-row>
    </div>

    <div class='stripe-elements-card' ref="card"></div>

    <div :class="['error', 'text-center', {'show-error': errors}]">
      <p class="xsmall-gutter-top no-gutter-bottom alert-color" v-if='errors' v-text="stripeError.message"></p>
      <p class="xsmall-gutter-top no-gutter-bottom" v-else>&nbsp;</p>
    </div>

    <p class="small grey" v-html="payment_disclaimer"></p>

    <mq-input-row v-if='showButtons' as='submit'>
      <div :class='submitButtonClasses'>
        <mq-button class='payment_method_edit_card_save' size='small' :color='primaryColor' :is_loading='is_loading'>{{ submitButtonLabel }}</mq-button>
      </div>
      <div :class='cancelButtonClasses'>
        <mq-button class='payment_method_edit_card_cancel' as='ghost' size='small' color='gray' @click.native.prevent='cancel' :is_disabled='is_loading'>{{ cancelButtonLabel }}</mq-button>
      </div>
    </mq-input-row>
  </form>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .stripe-elements-card-form {
    .stripe-elements-card {
      background-color: $white;
      padding: rem-calc(5) rem-calc(10);

      .mq_content__wrapper & {
        background-color: $nearly-white;
      }
    }

    .error{
      visibility: hidden;

      &.show-error {
        visibility: visible;
      }
    }
  }
</style>
