<script>
  import mqStripeInputStyle from '@moqomps/styles/mq-stripe-input'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    props: {
      stripe: {
        type: Object,
        default: () => null
      },
      stripe_secret: {
        type: String,
        required: true
      },
      owner_name: {
        type: String,
        required: true,
        default: ''
      },
      owner_email: {
        type: String,
        required: true,
        default: ''
      },
      backend_errors: {
        type: Object,
        default: () => {}
      },
      is_loading: {
        type: Boolean,
        required: false,
        default: false
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      allow_submit: {
        type: Boolean,
        required: false,
        default: true
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      },
      payment_disclaimer: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        stripeError: null,
        ownerName: this.owner_name,
        iban: null
      }
    },
    computed: {
      showButtons () {
        return !(this.event_bus)
      },
      submitButtonLabel () {
        return this.buttons_config?.submit?.label ||
          this.$t('buttons.save')
      },
      submitButtonClasses () {
        return this.buttons_config?.submit?.classes ||
          'column medium-12 medium-push-12 text-left text-center-for-small-only'
      },
      cancelButtonLabel () {
        return this.buttons_config?.cancel?.label ||
          this.$t('buttons.cancel')
      },
      cancelButtonClasses () {
        return this.buttons_config?.cancel?.classes ||
          'column medium-12 medium-pull-12 text-right text-center-for-small-only'
      },
      errors () {
        if (Object.keys(this.backend_errors).length) {
          this.$emit('payment-method-creating', {loading: false})

          let errorString = ''

          Object.keys(this.backend_errors).forEach(errorKey => {
            errorString += this.backend_errors[errorKey].join('<br />')
          })

          this.stripeError = {
            message: errorString
          }
        }

        return this.stripeError
        // return this.stripe_messages.iban ? this.stripe_messages.iban.error : null
      }
    },
    created () {
      if(!!(this.event_bus)) {
        this.event_bus.$on('submit', this.create)
      }
    },
    mounted () {
      this.iban = this.$stripeElements.create('iban', {
        ...mqStripeInputStyle,
        supportedCountries: ['SEPA']
      })
      this.iban.mount(this.$refs.iban)

      this.iban.addEventListener('change', () => {
        this.stripeError = null
      })

      this.$el.querySelectorAll('a').forEach(link => { link.target = '_blank' })
    },
    beforeDestroy () {
      if(!!(this.event_bus)) {
        this.event_bus.$off('submit', this.create)
      }

      this.iban.destroy()
    },
    methods: {
      create () {
        if (!this.allow_submit) {
          if (!!this.event_bus) {
            this.event_bus.$emit('failed-submit')
          }
          return false
        }

        this.$emit('payment-method-creating', {loading: true})

        let extraDetails = {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: {
              name: this.ownerName,
              email: this.owner_email
            }
          }
        }

        this.$stripe.confirmSepaDebitSetup(this.stripe_secret, extraDetails).then(response => {
          if (response.setupIntent) {
            this.$emit('payment-method-create', 'iban', response.setupIntent)
          }

          if (response.error) {
            this.$emit('payment-method-creating', {loading: false})
            this.stripeError = response.error
          }
        })
      },
      submit () {
        this.$emit('submit')
      },
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <form @submit.prevent='create' class='stripe-elements-iban-form'>
    <div class="medium-gutter-bottom">
      <mq-input-row>
        <mq-input standalone field='owner_name' v-model='ownerName' :label="$t('payment.label.bank_account_owner')" size='medium-24'></mq-input>
      </mq-input-row>
    </div>

    <div class='stripe-elements-iban' ref="iban"></div>

    <div :class="['error', 'text-center', {'show-error': errors}]">
      <p class="xsmall-gutter-top no-gutter-bottom alert-color" v-if='errors' v-text="stripeError.message"></p>
      <p class="xsmall-gutter-top no-gutter-bottom" v-else>&nbsp;</p>
    </div>

    <p class="small grey" v-html="payment_disclaimer"></p>

    <mq-input-row v-if='showButtons' as='submit'>
      <div :class='submitButtonClasses'>
        <mq-button class='payment_method_edit_sepa_save' size='small' :color='primaryColor' :is_loading='is_loading'>{{ submitButtonLabel }}</mq-button>
      </div>
      <div :class='cancelButtonClasses'>
        <mq-button class='payment_method_edit_sepa_cancel' as='ghost' size='small' color='gray' @click.native.prevent='cancel' :is_disabled='is_loading'>{{ cancelButtonLabel }}</mq-button>
      </div>
    </mq-input-row>
  </form>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .stripe-elements-iban-form {
    .stripe-elements-iban {
      background-color: $white;
      padding: rem-calc(5) rem-calc(10);

      .mq_content__wrapper & {
        background-color: $nearly-white;
      }
    }

    .error{
      visibility: hidden;

      &.show-error {
        visibility: visible;
      }
    }
  }
</style>
