<template>
  <div :class="['toast-container', _toastConfig.positionClass]">
    <transition-group :name='_toastConfig.animation'>
      <toast v-for='t in toasts' class='toast' :toast='t' :key='t.toastId'></toast>
    </transition-group>
  </div>
</template>
<script>
import { ToastServiceBus } from '../services/toastServiceBus'
import Timer from '../utils/timer'
import ToastConfig from '../utils/toastConfig'
import { ADD_TOAST, REMOVE_TOAST } from '../utils/constants'
import Toast from './Toast.vue'

export default {
  name: 'toast-container',

  data: () => ({
    toasts: []
  }),

  components: {
    'toast': Toast
  },

  props: ['toastConfig'],

  methods: {
    addToast(toast, toastConfig) {
      toast.toastConfig = toastConfig

      if (toast.toastContainerId && toastConfig.toastContainerId &&
        toast.toastContainerId !== toastConfig.toastContainerId) {
        return
      }

      if (!toast.type) {
        toast.type = toastConfig.defaultTypeClass
      }

      if (toastConfig.preventDuplicates && this.toasts.length > 0) {
        if (toast.toastId &&
          this.toasts.some(t => t.toastId === toast.toastId)) {
          return
        } else if (this.toasts.some(t => t.body === toast.body)) {
          return
        }
      }

      this.setCloseOptions(toast, toastConfig)

      toast.bodyOutputType = toast.bodyOutputType || toastConfig.bodyOutputType

      Timer.configureTimer(toast)

      if (toastConfig.newestOnTop) {
        this.toasts.unshift(toast)
        if (this.isLimitExceeded(toastConfig)) {
          this.toasts.pop()
        }
      } else {
        this.toasts.push(toast)
        if (this.isLimitExceeded(toastConfig)) {
          this.toasts.shift()
        }
      }

      if (toast.onShowCallback) {
        toast.onShowCallback(toast)
      }
    },

    setCloseOptions(toast, toastConfig) {
      if (toast.showCloseButton === null ||
        typeof toast.showCloseButton === 'undefined') {
        if (typeof toastConfig.showCloseButton === 'object') {
          toast.showCloseButton = toastConfig.showCloseButton[toast.type]
        } else if (typeof toastConfig.showCloseButton === 'boolean') {
          toast.showCloseButton = toastConfig.showCloseButton
        }
      }

      if (toast.showCloseButton) {
        toast.closeHtml = toast.closeHtml || toastConfig.closeHtml
      }
    },

    isLimitExceeded(toastConfig) {
      return toastConfig.limit && this.toasts.length > toastConfig.limit
    },

    removeToast(toast) {
      if (toast === null || typeof toast === 'undefined') return
      var index = this.toasts.indexOf(toast)
      if (index < 0) { return }

      this.toasts.splice(index, 1)

      if (toast.timeoutId) {
        clearTimeout(toast.timeoutId)
        toast.timeoutId = null
      }

      if (toast.onHideCallback) {
        toast.onHideCallback(toast)
      }
    },

    removeToasts(toastId, toastContainerId) {
      if (toastContainerId === null ||
        typeof toastContainerId === 'undefined' ||
        toastContainerId === this._toastConfig.toastContainerId) {
        if (toastId) {
          this.removeToast(this.toasts.filter(t => t.toastId === toastId)[0])
        } else {
          this.removeAllToasts()
        }
      }
    },

    removeAllToasts() {
      for (let i = this.toasts.length - 1; i >= 0; i--) {
        this.removeToast(this.toasts[i])
      }
    }
  },

  computed: {
    _toastConfig() {
      return new ToastConfig(this.toastConfig)
    }
  },

  created() {
    ToastServiceBus.subscribers.push(this)

    ToastServiceBus.$on(ADD_TOAST, (toast) => {
      this.addToast(toast, this._toastConfig)
    })

    ToastServiceBus.$on(REMOVE_TOAST,
      (toastId, toastContainerId) => {
        this.removeToasts(toastId, toastContainerId)
      }
    )
    
    ToastServiceBus.bufferedToasts.forEach((t) => {
      this.addToast(t, this._toastConfig)
    })
  },

  destroyed () {
    ToastServiceBus.$off(ADD_TOAST)
    ToastServiceBus.$off(REMOVE_TOAST)
  }
}
</script>

<style lang="scss">
  .toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: auto;
  }
  
  #toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .vot-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }
  
  .vot-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  
  .vot-top-left {
    top: 12px;
    left: 12px;
  }
  
  .vot-top-center {
    top: 12px;
  }
  
  .vot-top-right {
    top: 12px;
    right: 12px;
  }
  
  .vot-bottom-right {
    right: 30px;
    bottom: 30px;
  }
  
  .vot-bottom-center {
    bottom: 30px;
  }
  
  .vot-bottom-left {
    bottom: 30px;
    left: 12px;
  }
  
  .vot-center {
    top: 45%;
  }
  
  .toast-container {
    > div {
      margin: 0 0 6px;
      padding: 15px 15px 15px 10px;
      width: 300px;
      -moz-border-radius: 3px 3px 3px 3px;
      -webkit-border-radius: 3px 3px 3px 3px;
      border-radius: 3px 3px 3px 3px;
      -moz-box-shadow: 0 0 12px #999;
      -webkit-box-shadow: 0 0 12px #999;
      box-shadow: 0 0 12px #999;
      color: #fff;
    }
    &.toast-center, &.toast-top-center, &.toast-bottom-center {
      width: 100%;
      pointer-events: none;
      left: 0;
      right: 0;
    }
    &.toast-center > div, &.toast-top-center > div, &.toast-bottom-center > div {
      margin: 6px auto;
      pointer-events: auto;
    }
    &.toast-center > button, &.toast-top-center > button, &.toast-bottom-center > button {
      pointer-events: auto;
    }
    
    .ease-out-right-enter {
      opacity: 0;
      transform: translateX(200%);
    }
    .ease-out-right-enter-to {
      transition: all 0.5s;
      transform: translateX(0%);
    }
    .ease-out-right-leave-to {
      opacity: 0;
      transition: all 0.5s;
      transform: translateX(100%);
    }
    .ease-out-left-enter {
      opacity: 0;
      transform: translateX(-200%);
    }
    .ease-out-left-enter-to {
      transition: all 0.5s;
      transform: translateX(0%);
    }
    .ease-out-left-leave-to {
      opacity: 0;
      transition: all 0.5s;
      transform: translateX(-100%);
    }
    .ease-out-right-leave-active, .ease-out-left-leave-active {
      position: absolute;
    }
    .ease-out-right-move, .ease-out-left-move {
      transition: all 0.5s;
    }
    .fade-enter-active {
      transition: all 0.5s linear;
    }
    .fade-leave-active {
      transition: all 0.5s linear;
      position: absolute;
      width: 100%;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
      transform: translateX(0px);
    }
    .fade-move {
      transition: all 0.5s;
    }
  }
</style>
