<script>
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'

import { currentLocale } from '@/current_user'
import allCountries from "@/resources/locales/countries"

export default {
  props: {
    value: { type: String },
    preferredCountries: { type: Array, required: true }
  },
  mounted() {
    let localizedCountries = allCountries(currentLocale())
    localizedCountries = Object.keys(localizedCountries).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = localizedCountries[key];
      return accumulator;
    }, {});

    this.iti = intlTelInput(this.$refs.nativeInput, {
      nationalMode: true,
      preferredCountries: this.preferredCountries,
      localizedCountries: localizedCountries
    });

    if(this.value) {
      this.iti.setNumber(this.value);
    }
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  methods: {
    onInputChange() {
      if(!this.iti) {
        return
      }

      if(this.iti.isValidNumber()) {
        this.internalValue = this.iti.getNumber()
      } else {
        // Required as otherwise the input will send the raw user input. If this gets accepted by the backend (because it works
        // coincidentally with the users country), it feels like a success to the user that the current country and number
        // was saved although the number with the users backend country was saved. By forcing it to an invalid value on JS side,
        // it feels much more consistent for the user (without needing to cancel any events on JS side only).
        this.internalValue = 'INVALID_BY_JS'
      }
    }
  },
  watch: {
    internalValue() {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<template>
  <input @countrychange="onInputChange" @keyup="onInputChange" ref="nativeInput" v-bind="$attrs">
</template>

<style lang="scss">
  $flagsImagePath: '~@moqomps/assets/images/';
  @import 'intl-tel-input/src/css/intlTelInput.scss';

  ul.iti__country-list li {
    font-size: 1rem;
    margin-bottom: 0;
  }

  // We need some adjustments if embedded inside a "floating-label"
  .has-float-label {
    // no hover as other inputs don't have it eigher + it has issues with the label
    .iti__flag-container:hover .iti__selected-flag {
      background: none !important;
    }
    // add some spacing for the label
    .iti__selected-flag {
      padding-top: 10px;
    }
  }
</style>
