<script>
  import { rolePathPrefix } from '@/current_user'
  import Resource from '@/resources/resources'
  import Stripe from './stripe'
  import Payunity from './payunity'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    components: {
      Stripe,
      Payunity
    },
    props: {
      disallow_sepa_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      allow_paypal_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      allow_submit: {
        type: Boolean,
        required: false,
        default: true
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      },
      payment_disclaimer: {
        type: String,
        required: true
      },
      skip_payin_source_saving: {
        type: Boolean,
        required: false,
        default: false
      },
      owner_name: {
        type: String,
        required: false,
        default: ''
      },
      owner_email: {
        type: String,
        required: false,
        default: ''
      },
      create_with_type: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        newPayinSource: Resource({ name: 'payin_sources', path: `${rolePathPrefix()}/api/payin_sources/new`, skipFetching: true, singularResource: true }),
        payinSources: Resource({ name: 'payin_sources', path: `${rolePathPrefix()}/api/payin_sources`, skipFetching: true, singularResource: true, persisted: true, wrapped: false }),
        stripeKey: null,
        stripeSecret: null,
        paymentProvider: null,
        payunityJsWidgetUrl: null,
        is_loading: false
      }
    },
    created () {
      this.newPayinSource.$on('get', this.intializePaymentProvider)
      this.newPayinSource.fetchRecord()

      this.payinSources.$on('error', () => {
        window.setTimeout(() => {
          this.is_loading = false
          this.$emit('payment-method-creating', {loading: false})
        }, 500)
      })

      this.payinSources.$once('receive', () => {
        window.setTimeout(() => {
          this.$emit('payment-method-created')
          this.is_loading = false
        }, 500)
      })

      this.payinSources.$once('validation', () => {
        window.setTimeout(() => {
          this.is_loading = false
          this.$emit('payment-method-creating', {loading: false})
        }, 500)
      })
    },
    beforeDestroy () {
      this.newPayinSource.$off('get', this.intializePaymentProvider)
    },
    computed: {
      options () {
        return {
          backend_errors: this.payinSources.errors,
          owner_name: this.owner_name,
          owner_email: this.owner_email,
          buttons_config: this.buttons_config,
          allow_submit: this.allow_submit,
          stripe_secret: this.stripeSecret,
          stripe_key: this.stripeKey,
          payunity_js_widget_url: this.payunityJsWidgetUrl,
          payunity_brands: this.payunityBrands,
          allow_paypal_payments: this.allow_paypal_payments,
          disallow_sepa_payments: this.sepaDirectDebitUnavailable || this.disallow_sepa_payments,
          allow_sepa_sofort_setup: this.sepaSofortSetupAvailable,
          event_bus: this.event_bus,
          payment_disclaimer: this.payment_disclaimer,
          is_loading: this.is_loading,
          create_with_type: this.create_with_type
        }
      },
      paymentProviderComponent () {
        return this.paymentProvider
      },
      listeners () {
        return {
          'cancel': this.cancel,
          'payment-method-create': this.paymentMethodCreate,
          'payment-method-creating': this.paymentMethodCreating
        }
      }
    },
    methods: {
      intializePaymentProvider () {
        this.setData()

        this.is_loading = false
        this.$emit('payment-method-creating', {loading: false})
      },
      setData () {
        this.stripeSecret = this.newPayinSource.record.payment_client_secret
        this.stripeKey = this.newPayinSource.record.payment_public_key
        this.paymentProvider = this.newPayinSource.record.payment_provider
        this.payunityJsWidgetUrl = this.newPayinSource.record.payunity_js_widget_url
        this.payunityBrands = this.newPayinSource.record.payunity_brands
        this.sepaDirectDebitUnavailable = this.newPayinSource.record.payment_method_types && !this.newPayinSource.record.payment_method_types.includes('sepa_debit')
        this.sepaSofortSetupAvailable = this.newPayinSource.record.payment_method_types && this.newPayinSource.record.payment_method_types.includes('sofort')
      },
      paymentMethodCreate(paymentBy, response) {
        this.is_loading = true

        if (!!this.event_bus && this.skip_payin_source_saving) {
          this.event_bus.$emit('payment-method-created', paymentBy, response)
          return
        }

        let token = response.client_secret || response.id

        this.payinSources.create({payload: {source_token: token, destination_token: token}})
      },
      paymentMethodCreating (stripeElementEvent) {
        this.$emit('payment-method-creating', stripeElementEvent)
      },
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>

<template>
  <div>
    <component :is='paymentProviderComponent' v-bind='options' v-on='listeners'></component>
  </div>
</template>

<style lang="scss">
  ul.mq_tabs__tab_list li {
    min-width: 0 !important;
  }
</style>
