<script>
  import MqButton from './MqButton'

  export default {
    components: {
      MqButton
    },

    props: {
      icon: {
        type: String,
        required: false,
        default: null
      },
      icon_color: {
        type: String,
        required: false,
        default: '#FFF'
      },
      top_color: {
        type: String,
        required: false,
        default: null
      },
      top_text_color: {
        type: String,
        required: false,
        default: '#FFF'
      },
      bottom_color: {
        type: String,
        required: false,
        default: '#FFF'
      },
      bottom_text_color: {
        type: String,
        required: false,
        default: null
      },
      button_label: {
        type: String,
        required: false,
        default: null
      },
      button_destination: {
        type: String,
        required: false,
        default: null
      },
    },
    
    computed: {
      topContainerStyle () {
        return {
          backgroundColor: this.top_color || this.primaryColor,
          color: this.top_text_color
        }
      },

      bottomContainerStyle () {
        return {
          backgroundColor: this.bottom_color,
          color: this.bottom_text_color
        }
      },
      
      buttonSlotAvailable () {
        return !!this.$slots['button']
      },

      bottomSlotAvailable () {
        return !!this.$slots['bottom']
      }
    }
  }
</script>


<template>
  <div class='mq-extended-panel'>
    <div class='panel-container panel-container--top' :style='topContainerStyle'>
      <div class='top-content' :class="{'top-content--with-button': buttonSlotAvailable}">
        <div class='content-container'>
          <slot name='top'></slot>
        </div>
        <div v-if='buttonSlotAvailable' class='button-container gutter-top-for-small-only text-center'>
          <slot name='button'></slot>
        </div>
      </div>
    </div>
    <div v-if='bottomSlotAvailable' class='panel-container panel-container--bottom' :style='bottomContainerStyle'>
      <slot name='bottom'></slot>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .mq-extended-panel{
    .panel-container {
      padding: rem-calc(20);
      overflow: hidden;
      
      &--bottom {
        background-color: #fff;
      }
    }

    .top-content {
      &--with-button {
        padding-left: 0;

        @include breakpoint(medium up) {
          display: table-row;
        }
  
        .content-container {
          display: table-cell;
          vertical-align: middle;
          width: 100%;
        }

        .button-container {
          padding: 0 rem-calc(3) 0 0;
          height: 100%;

          @include breakpoint(medium up) {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>
