<script>
  import MqInputDefaults from '@moqomps/mixins/MqInputDefaults'
  import showPasswordButton from './partials/showPasswordButton'
  import zxcvbn from 'zxcvbn'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    mixins: [MqInputDefaults],

    components: {showPasswordButton},

    props: {
      placeholder: {
        type: String,
        required: false,
        default: ''
      },
      password_min_length: {
        type: Number,
        default: 8
      },
      confirmation: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        hintStatusClass: '',
        inputType: 'password',
        thisHint: this.$t('password.hint')
      }
    },

    computed: {
      options () {
        return {
          disabled: this.inputViewMode,
          placeholder: this.inputPlaceholder,
          required: this.required,
          id: this.fieldId,
          class: this.inputClasses,
          type: this.inputType,
          name: this.field,
          autocomplete: 'new-password',
        }
      },

      password () {
        return this.form.record[this.field] || ''
      },

      inputHint () {
        if(this.confirmation) {
          this.hintStatusClass = 'alert-color'
          if (this.showError) return null
          return this.thisHint
        }

        if(this.password.length === 0) {
          this.hintStatusClass = this.showError ? 'alert-color' : ''
          this.$emit('event', {value: false})
          return this.thisHint
        } else if(this.password.length < this.password_min_length) {
          this.handleScore(0)
          return this.$t('password.meter.score')[0]
        } else {
          let score = Math.max(1, zxcvbn(this.password).score)

          this.handleScore(score)
          return this.$t('password.meter.score')[score]
        }
      },

      inputClasses () {
        return [
          'mq-input',
          {'show-placeholder': this.inputPlaceholder !== ' '}
        ]
      },

      inputHintClasses () {
        return [
          'input-hints',
          {'permanent': (this.permanent_hint || this.showError)},
          this.hintStatusClass
        ]
      },

      inputPlaceholder () {
        return this.inputViewMode ? '●●●●●●●' : ' '
      }
    },
    methods: {
      handleScore (score) {
        if (score < 2) {
          this.$emit('event', {value: false})
          this.hintStatusClass = 'alert-color'
          return
        }
        this.$emit('event', {value: true})
        this.hintStatusClass = ''
      },
      togglePasswordVisibility () {
        this.inputType = this.inputType === 'password' ? 'text' : 'password'
      }
    }
  }
</script>


<template>
  <div v-if="form.record && field" :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <input v-model='form.record[field]' v-bind='options'/>

      <label :for='fieldId'>{{ $t('password.label') }}</label>

      <div v-show='!inputViewMode' v-if='inputHint' v-html='inputHint' :class='inputHintClasses'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>

      <show-password-button v-show='!inputViewMode' @click.native='togglePasswordVisibility' :input_type='inputType' />
    </div>
  </div>
</template>


<style lang="scss">
  .mq-input-container {
    input[type='password'] {
      letter-spacing: 0.15em;
    }

    input::-ms-reveal {
      display:none;
    }

    .show-placeholder {
      font-size: 60% !important;
      opacity: 0.5 !important;
      letter-spacing: 0.5em !important;
      padding-top: 2.3em !important;
    }
  }
</style>
