<script>
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    props: {
      pagination: {
        type: Object,
        required: true
      }
    },

    methods: {
      gotoNextPrevPage(page) {
        this.$emit('set-page', this.pagination[page])
      }
    },
  }
</script>


<template>
  <nav>
    <ul class='pagination text-center'>
      <li v-if='this.pagination.prev_page' class='pagination-previous'><a @click="gotoNextPrevPage('prev_page')">{{ $t('buttons.previous') }}</a></li>
      <li v-if='this.pagination.next_page' class='pagination-next'><a @click="gotoNextPrevPage('next_page')">{{ $t('buttons.next') }}</a></li>
    </ul>
  </nav>
</template>


<style lang='scss'>
  .pagination {
    padding-left: 0
  }

  .pagination-previous {
    float: left
  }

  .pagination-next {
    float: right
  }
  
</style>