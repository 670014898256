export default {
  methods: {
    openModal(component, options={}) {


        this.$modal.show(
          // component or component definition
          component,

          // component properties
          options.props,

          // modal parameters
          {
            adaptive: true,
            width: '715px',
            height: 'auto',
            scrollable: true
          },

          // modal event listeners
          // emiting from component with 'this.$parent.$emit('my-event')
          options.on
        )

    }
  }
}