<script>
  export default {
    props: {
      form: {
        type: Object,
        required: false,
        default: null
      },
      field: {
        type: String,
        required: false,
        default: null
      },
      standalone: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      placeholder: {
        type: String,
        required: false,
        default: ' ' // has to be a space for floating labels
      },
      value: {
        // required: true
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: ''
      },
      color: {
        type: String,
        required: false,
        default: ''
      },
      error: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        standaloneVal: this.value === undefined ? null : this.value
      }
    },
    computed: {
      showError () {
        return !!this.fieldError && !this.form?.inputOptions?.viewMode
      },
      fieldError () {
        if (this.error) return [this.error]
        if (this.form && this.form.validation[this.field]?.length) return this.form.validation[this.field]
        return null
      },
      fieldId () {
        if (this.standalone) return `select_${Math.random().toString(36).slice(2)}`
        return `${this.field}_${Math.random().toString(36).slice(2)}`
      },
      inputLabel () {
        if (this.form) return this.$t(`form.label.${this.form.name}.${this.field}`)
        return this.label
      },
      hasFloatLabelClass () {
        return {'has-float-label': this.inputLabel}
      },
      placeholderClass () {
        if (this.form) {
          if(!this.form.record[this.field]) return 'virtual-placeholder-shown'
          return ''
        }

        if (this.standaloneVal == null ) return 'virtual-placeholder-shown'
        return ''
      },
      nothingSelected () {
        return !(this.form?.record[this.field] || this.standaloneVal != null);
      },
      inputPlaceholder () {
        return this.placeholder ? this.placeholder : ''
      },
      inputViewMode () {
        if(this.form && this.form.inputOptions) return this.form.inputOptions.viewMode
        return this.disabled
      },
      options () {
        return {
          disabled: this.inputViewMode,
          placeholder: this.inputPlaceholder,
          required: this.required,
          id: this.fieldId,
          class: this.selectClasses,
          name: this.field
        }
      },

      selectClasses () {
        return [
          'mq-select',
          this.color,
          this.placeholderClass
        ]
      },

      selectWrapperClasses () {
        return [
          `column ${this.size}`,
          {'required' : this.required},
          {'error': this.showError}
        ]
      }
    },
    watch: {
      standaloneVal (newValue) {
        if (this.standalone) {
          this.$emit('input', newValue)
        }
      }
    },
  }
</script>


<template>
  <div v-if='standalone' :class="selectWrapperClasses">
    <div :class='hasFloatLabelClass'>
      <select v-model='standaloneVal' v-bind='options'>
        <option v-if="nothingSelected" :value="null" disabled selected></option>
        <slot></slot>
      </select>

      <label v-if='inputLabel' :for='fieldId'> {{ inputLabel }} </label>

      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>

  <div v-else-if="form.record && field" :class="selectWrapperClasses">
    <div :class='hasFloatLabelClass'>
      <select v-model='form.record[field]' v-bind='options'>
        <option v-if="nothingSelected" :value="null" disabled selected></option>
        <slot></slot>
      </select>

      <label v-if='inputLabel' :for='fieldId'> {{ inputLabel }} </label>

      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  $background-color-white: $white;
  $text-color-white: $black;
  $background-color-dark: $nearly-white;
  $text-color-dark: $black;

  @-webkit-keyframes autofill-white {
    to {
      color: #666;
      background: $background-color-white;
    }
  }

  @-webkit-keyframes autofill-dark {
    to {
      color: #666;
      background: $background-color-dark;
    }
  }

  select.mq-select {
    border: none;
    width: 100%;
    margin: 0;
    padding: rem-calc(15) rem-calc(8) rem-calc(15) rem-calc(10);
    box-shadow: none;
    border-radius: 0;
    color: $black;
    font-size: rem-calc(18);
    background-color: $white;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2853, 53, 53%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right rem-calc(5) top rem-calc(7);
    background-repeat: no-repeat;
    background-size: rem-calc(9) rem-calc(6);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 2px solid transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: $background-color-white !important;
      -webkit-text-fill-color: $text-color-white !important;
      -webkit-animation-name: autofill-white;
      -webkit-animation-fill-mode: both;
    }

    .has-float-label & {
      height: rem-calc(49.2);
      padding: rem-calc(20) rem-calc(22) rem-calc(10) rem-calc(12);
      background-position: right rem-calc(-10) top rem-calc(3);
    }

    &.white {
      background-color: $white;
    }

    .mq_content__wrapper &,
    .dark-inputs & {
      background-color: $nearly-white;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: $text-color-dark !important;
        -webkit-animation-name: autofill-dark;
        -webkit-animation-fill-mode: both;
      }

      &:disabled {
        color: $black;
        background-color: $white;
        background-image: none;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: $black !important;
          -webkit-animation-name: autofill-white;
          -webkit-animation-fill-mode: both;
        }
      }
    }

    &:focus{
      outline: none;
      box-shadow: none;
      // border-color set in MOQOmponents/mixins/MqInputFoundation.js
    }

    .error & {
      border-bottom-color: $alert-color;
    }

    &.virtual-placeholder-shown {
      color: rgba($dark-gray, 0.5);
    }

    &:disabled {
      color: $black;
      background-color: transparent;
      background-image: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: $black !important;
        -webkit-animation-name: autofill-white;
        -webkit-animation-fill-mode: both;
      }
  
      &::-ms-value {
        color: $black;
      }
      
      &::-ms-expand {
        display: none;
      }
    }

    option[disabled][selected] {
      display: none;
    }
  
    &::-ms-expand {
      border: none;
      background: transparent;
      margin-top: -7px;
    }
  }
</style>
