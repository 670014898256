<script>
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,
    props: {
      payin_sources: {
        type: Object,
        required: true
      },
      theme: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      payinSources() {
        return this.payin_sources.payin_sources
      }
    },
    methods: {
      replace () {
        this.$emit('payment-method-replacing')
      }
    }
  }
</script>


<template>
  <div>
    <mq-table class='payment_source_list' :theme='theme'>
      <thead>
        <tr>
          <th colspan='2'>{{ $t('payment.sources_list.header') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payinSource in payinSources" :key="payinSource.id">
          <td>
            <span>
              {{ payinSource.title }}
              <strong>{{ payinSource.primary ? $t('payment.sources_list.primary') : '' }}</strong>
            </span>
          </td>
          <td class='replace_button text-center-vertical'>
            <a v-if='!payinSource.primary' class='payment_method_edit' @click="$emit('make-primary', payinSource.id)">
              {{ $t('payment.sources_list.make_primary') }}
            </a>
            <a v-if='payinSource.removable' class='payment_method_edit' @click="$emit('remove', payinSource.id)">
              {{ $t('payment.sources_list.remove') }}
            </a>
          </td>
        </tr>
      </tbody>
    </mq-table>
    <div class="row columns gutter-top text-right">
      <a class="mq_link add-payment-method" @click="replace">{{ $t('payment.sources_list.add') }}</a>
    </div>
  </div>
</template>

<style lang='scss'>
  @import '~@moqomps/style';

  @include breakpoint(small only) {
    table.mq-table.payment_source_list {
      display: block;

      thead,
      tbody{
        display: block;
        width: 100%;
      }

      td {
        display: block;
        padding: rem-calc(10) rem-calc(15);

        span {
          display: inline-block;
          text-align: left;
        }

        &:after,
        &:before {
          content: none;
        }

        &.replace_button {
          text-align: center;
        }
      }

      thead tr {
        position: static;

        th {
          display: block;
          text-align: center;
        }
      }

    }
  }
</style>
