<script>
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    inject: {
      '_form': { default: null },
      '_field_labels': { default: null }
    },

    props: {
      form: {
        type: Object,
        required: false,
        default () {
          return this._form
        }
      },

      field_labels: {
        type: Object,
        required: false,
        default () {
          return this._field_labels
        }
      },

      field_filter: {
        type: Array,
        required: false,
        default: null
      },

      error_message: {
        type: String,
        required: false,
        default: null
      }
    },

    data() {
      return {
        requestError: null,
        backendError: null
      }
    },

    computed: {
      filteredValidations () {
        if (!this.form || !Object.keys(this.form.validation).length) return null
        if (!this.field_filter) return this.form.validation

        let validations = JSON.parse(JSON.stringify(this.form.validation))

        this.field_filter.forEach((field) => {
          delete validations[field]
        })

        return Object.keys(validations).length ? validations : null
      },

      errorMessage () {
        return this.error_message || this.requestError
      },
    },

    created () {
      if (!this.form) return
      this.form.$on('error', this.setServerError)
      this.form.$on('validation', this.setValidationError)
      // Remove error on all successful requests
      this.form.$on(['update', 'get', 'create', 'destroy'], this.resetErrors)
    },

    beforeDestroy () {
      if (!this.form) return
      this.form.$off('error', this.setServerError)
      this.form.$off('validation', this.setValidationError)
      this.form.$off(['update', 'get', 'create', 'destroy'], this.resetErrors)
    },

    methods: {
      setServerError () {
        if (!this.form) return
        this.requestError = this.$t('error.server')
        // Get the message from errors object
        // this could be 'update', 'get', 'create'
        let action = Object.keys(this.form.errors)[0]
        this.backendError = this.form.errors[action].response.data.error_message
      },

      setValidationError () {
        this.requestError = this.$t('error.update')
      },

      resetErrors () {
        this.requestError = null
        this.backendError = null
      },

      translatedFieldName (key) {
        if (this.field_labels?.hasOwnProperty(key)) {
          return this.field_labels[key]
        }

        return ''
      }
    }
  }
</script>


<template>
  <div class='column small-18 small-offset-3 medium-24 medium-offset-0 small-gutter-bottom'>
    <p v-if='errorMessage' v-html='errorMessage' class='error-message'></p>
    <p v-if='backendError' v-html='backendError' class='error-message'></p>
    
    <dl v-if='filteredValidations' class='error-message'>
      <template v-for='(message, key) in filteredValidations'>
        <dt class='col-sm-3'>{{ translatedFieldName(key) }}</dt>
        <dd v-for='content in message' class='col-sm-9'>{{ content }}</dd>
      </template>
    </dl>
  
    <slot></slot>
  </div>
</template>


<style lang='scss' scoped>
  @import '~@moqomps/style';

  dl {
    margin-top: 0;
  }

  dt {
    font-weight: 600;
  }

  dd {
    margin-top: 0;
    margin-bottom: rem-calc(5);
    margin-left: rem-calc(10);
    
    &:before {
      content: '- ';
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .error-message {
    color: $alert-color;

    @include breakpoint(small only) {
      font-size: rem-calc(12)
    }
  }
</style>