export default function(payunity_js_widget_url) {
  const scriptId = "payunity-script"

  if (document.getElementById(scriptId)) {
    return
  }

  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = payunity_js_widget_url 
  script.id = scriptId

  document.body.appendChild(script)
}
