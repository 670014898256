<script>
  import tinycolor from 'tinycolor2'

  export default {
    props: {
      table_data: {
        type: [Array, Function],
        required: false,
        default: () => []
      },
      theme: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      classes () {
        return [
          'mq-table',
          this.theme
        ]
      }
    },
    beforeMount () {
      this.createTableStyle()
    },
    methods: {
      createTableStyle () {
        const color = tinycolor(this.primaryColor).getLuminance() > 0.65 ? '#000000' : '#FFFFFF'

        const style = `
          .mq-table thead {
            background-color: ${this.primaryColor};
            color: ${color};
          }

          .mq-table td:after {
            background-color: ${this.primaryColor};
          }

          .mq-table td:before {
            color: ${color};
          }
        `
        this.createCustomStyle(style)
      }
    }
  }
</script>


<template>
  <table :class='classes'>
    <slot>
      <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td data-table-header="First Name">James</td>
        <td data-table-header="Last Name">Doe</td>
      </tr>
      <tr>
        <td data-table-header="First Name">Bob</td>
        <td data-table-header="Last Name">Gold</td>
      </tr>
      </tbody>
    </slot>
  </table>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  table.mq-table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    @include breakpoint(small only) {
      display: block;

      thead,
      tbody{
        display: block;
        width: 100%;
      }
    }
    /*
    // Zebra striping - if needed
    tr:nth-of-type(odd) {
      background: #eee;
    }
    */
    td, th {
      padding: rem-calc(10) rem-calc(15);
      text-align: left;

      &:last-child {
        text-align: right;

        @include breakpoint(small only) {
          text-align: left;
        }
      }

      &:first-child {
        text-align: left;
      }
    }

    th {
      color: $white;
      border-bottom: 2px solid $white;

      @include breakpoint(small only) {
        display: block;
        width: 100%;
      }

      .mq_content__wrapper & {
        border-bottom: 2px solid $very-light-gray;
      }
    }

    td {
      border: none;
      background: $white;

      .mq_content__wrapper & {
        background: $very-light-gray;
      }

      vertical-align: top;

      &.text-center-vertical{
        vertical-align: middle;
      }

      @include breakpoint(small only) {
        position: relative;
        border: none;
        display: flex;
        align-items: center;
        padding: rem-calc(5);
        margin: 0 auto;
        width: 100%;
        hyphens: auto;
        word-break: break-word;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          min-width: 45%;
        }
        &:before {
          // Label the data
          content: attr(data-table-header);
          min-width: 44%;
          margin-right: rem-calc(15);
          display: block;
          flex: 0 1 auto;
          z-index: 1;
          align-self: flex-start;
        }
      }
    }

    tr{
      border-bottom: rem-calc(2) solid $very-light-gray;

      .mq_content__wrapper & {
        border-bottom-color: $white;
      }

      &:first-child td {
        padding-top: rem-calc(12);
      }



      @include breakpoint(small only) {
        display: block;
        width: 100%;
        border: none;
        padding-bottom: rem-calc(5);

        &:first-child td {
          padding-top: rem-calc(5);
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    @include breakpoint(small only) {
      // Hide table headers (but not display: none;, for accessibility)
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
  }
</style>