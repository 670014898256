<script>
  import MqInputDefaults from '@moqomps/mixins/MqInputDefaults'
  import IntlPhoneInput from './intlPhoneInput'

  export default {
    components: { IntlPhoneInput },
    mixins: [MqInputDefaults],
    data () {
      return {
        standaloneInputVal: this.value
      }
    },
    props: {
      preferredCountries: { type: Array, default: () => [] }
    },
    computed: {
      options () {
        return {
          preferredCountries: this.preferredCountries,
          disabled: this.inputViewMode,
          placeholder: this.inputPlaceholder,
          required: this.required,
          id: this.fieldId,
          class: 'mq-input',
          type: this.as,
          name: this.field
        }
      }
    },
    watch: {
      standaloneInputVal(val) {
        this.$emit('input', val);
      }
    }
  }
</script>


<template>
  <div v-if='standalone' :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <intl-phone-input v-model='standaloneInputVal' v-bind='options'/>

      <label v-if='inputLabel' :for='fieldId'>{{ inputLabel }}</label>

      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small' v-html="fieldError.join(' ')"></p>
      </div>
    </div>

    <a v-if='with_info' @click="$emit('info-event')" class='info-button'>
      <svg-icon name="info" size='40px' fill='#9e9e9e'></svg-icon>
    </a>
  </div>

  <div v-else-if='form.record && field' :class='inputWrapperClass'>
    <div :class="floatLabelClass">
      <intl-phone-input v-model='form.record[field]' v-bind='options'/>

      <label v-if='inputLabel' :for='fieldId'>{{ inputLabel }}</label>

      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small' v-html="fieldError.join(' ')"></p>
      </div>
    </div>

    <a v-if='with_info' @click="$emit('info-event')" class='info-button'>
      <svg-icon name='info' size='40px' fill='#9e9e9e'></svg-icon>
    </a>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .mq-input-container {
    position: relative;
  }

  .info-button {
    position: absolute;
    right: rem-calc(5);
    top: rem-calc(6);

    @include breakpoint(small only) {
      right: rem-calc(15);
    }
  }
</style>
