<script>
  import moment from 'moment'
  import MqInputDefaults from '@moqomps/mixins/MqInputDefaults'
  import MaskedInput from 'vue-text-mask'

  export default {
    mixins: [MqInputDefaults],

    components: {
      MaskedInput
    },

    data () {
      return {
        standaloneInputVal: this.value,
        formatedInputVal: null,
        applyTextMaskToDateInput: [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
    },

    computed: {
      inputLabel () {
        const dateFormat = `DD-MM-YYYY`

        if (this.label && this.label !== '') return `${this.label} (${dateFormat})`
        if (this.form) {
          const labelText = this.$t(`form.label.${this.form.name}.${this.field}`) + ' '
          return `${labelText} (${dateFormat})`
        }

        return dateFormat
      },

      options () {
        return {
          disabled: this.inputViewMode,
          placeholder: this.inputPlaceholder,
          required: this.required,
          id: this.fieldId,
          class: 'mq-input',
          type: 'text',
          name: this.field,
          mask: this.applyTextMaskToDateInput,
          keepCharPositions: true
        }
      }
    },

    beforeMount () {
      this.formatInputVal()

      if (!this.standalone) {
        this.form.$on('receive', this.formatInputVal)
      }
    },

    destroyed () {
      if (!this.standalone) {
        this.form.$off('receive', this.formatInputVal)
      }
    },

    methods: {
      formatInputVal () {
        if (!this.form.record || !this.form.record[this.field]) return
        this.formatedInputVal = moment(this.form.record[this.field], 'YYYY-MM-DD').format('DD-MM-YYYY')
      }
    },
    watch: {
      formatedInputVal (newValue, oldValue) {
        if (newValue.match(/\d/g)?.length === 8) {
          const date = moment(newValue, 'DD-MM-YYYY')
          if (date.isValid()) {
            this.form.record[this.field] = date.format('YYYY-MM-DD')
            this.setInputValidation(null)
          } else {
            this.form.record[this.field] = null
            // Mark the input field as invalid without error text
            this.setInputValidation('')
          }
        } else {
          this.setInputValidation(null)
          this.form.record[this.field] = null
        }
      },

      standaloneInputVal (val) {
        this.$emit('input', val);
      }
    }
  }
</script>

<template>
  <div v-if='standalone' :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <masked-input v-model='formatedInputVal' v-bind='options'></masked-input>

      <label :for='fieldId'>{{ inputLabel }}</label>

      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>

  <div v-else-if="form.record && field" :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <masked-input v-model='formatedInputVal' v-bind='options'></masked-input>

      <label :for='fieldId'>{{ inputLabel }}</label>

      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>

      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>
</template>
