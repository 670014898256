<script>
  import tinycolor from 'tinycolor2'

  export default {
    props: {
      navi_items: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        routeValue: this.$route.path
      }
    },
    computed: {
      isFramed () {
        return window.top.location != window.location
      }
    },
    beforeMount () {
      this.createLinkStyle()
    },
    methods: {
      itemClasses(item) {
        return [
          {'with-signal' : item.signal},
          {'with-signal--important' : item.signal_important},
        ].concat(item.class)
      },
      createLinkStyle () {
        const color = tinycolor(this.primaryColor)
        const colorRgb = tinycolor(this.secondaryColor).toRgb()

        let linkColor = '#FFFFFF'
        let hoverLinkColor = this.primaryColor
        let hoverLinkWeight = '400'

        if (color.getLuminance() > 0.65) {
          linkColor = '#000000'
          hoverLinkColor = '#000000'
          hoverLinkWeight = '600'
        }

        const linkStyle = `
          .mq_sub_navigation a.with-signal:before,
          .mq_sub_navigation a.with-signal--important:before {
            background-color: ${this.secondaryColor};
          }
          .mq_sub_navigation a.with-signal--important:before {
            animation: pulse 2s infinite;
          }
          .mq_sub_navigation a.router-link-exact-active.with-signal--important:before {
            animation: none;
          }

          .mq_sub_navigation a.router-link-exact-active {
            background-color: ${this.primaryColor};
            color: ${linkColor};
          }
          .mq_sub_navigation a:not(.router-link-exact-active):hover{
            color: ${hoverLinkColor};
            font-weight: ${hoverLinkWeight};
          }

          .mq_sub_navigation a.router-link-exact-active.with-signal:before,
          .mq_sub_navigation a.router-link-exact-active.with-signal--important:before {
            background-color: ${linkColor};
          }

          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0.8)
            }

            70% {
              box-shadow: 0 0 0 14px rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0)
            }

            100% {
              box-shadow: 0 0 0 0 rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0)
            }
          }
        `
        this.createCustomStyle(linkStyle)
      },

      breakFrame () {
        window.top.location = window.location
      }
    },
    watch: {
      routeValue (newValue, oldValue) {
        if (newValue === oldValue) return
        this.$router.push({ path: newValue }).catch(err => {})
      },
      "$route.path" (newValue, oldValue) {
        this.routeValue = this.$route.path
      }
    }
  }
</script>


<template>
  <div class='mq_sub_navigation'>
    <ul class='hide-for-small-only'>
      <li v-for='item in navi_items' >
        <router-link :to='item.href' :class='itemClasses(item)'>{{ item.title }}</router-link>
      </li>
      <li v-if='isFramed' class='framebreaker'><a @click='breakFrame'>Vollbild <span>↗</span></a></li>
    </ul>

    <select class='mq-select white show-for-small-only' v-model='routeValue'>
      <option v-for='item in navi_items' :value='item.href'>{{ item.title }}</option>
    </select>
  </div>
</template>


<style lang="scss">
  @import '~@moqomps/style';

  .mq_sub_navigation {
    box-shadow: 0 rem-calc(20) 0 rem-calc(-10) $light-gray;

    @include breakpoint(small only) {
      margin: rem-calc(30) 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;
        margin: 0;
        text-align: center;

        a {
          position: relative;
          font-size: rem-calc(16);
          font-weight: 400;
          line-height: 1;
          display: block;
          padding: rem-calc(15) rem-calc(8);
          background-color: $white;

          &:hover:after {
            content: '›';
            position: absolute;
            right: rem-calc(5);
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
            line-height: 0;
            font-size: rem-calc(35);
          }

          &.with-signal:before,
          &.with-signal--important:before {
            content: '';
            position: absolute;
            left: rem-calc(15);
            top: 50%;
            transform: translateY(-50%);
            height: rem-calc(15);
            width: rem-calc(15);
            border-radius: 50%;
            z-index: 1;

            @include breakpoint(medium only) {
              left: rem-calc(1);
              height: rem-calc(10);
              width: rem-calc(10);
            }
          }

          &.router-link-exact-active {
            font-weight: 600;

            &:after {
              content: '›';
              position: absolute;
              right: rem-calc(5);
              top: 50%;
              transform: translateY(-50%);
              font-weight: 400;
              line-height: 0;
              font-size: rem-calc(35);
            }
          }
        }
        
        &.framebreaker {
          border-top: 5px solid #ededed;

          span {
            font-size: 25px;
            position: relative;
            bottom: -2px;
          }

          a:hover:after {
            display: none;
          }
        }
      }
    }
  }
</style>
