<script>
  import MqInputDefaults from '@moqomps/mixins/MqInputDefaults'

  export default {
    props: {
      rows: {
        type: Number,
        required: false,
        default: 5
      }
    },
    mixins: [MqInputDefaults],
    data () {
      return {
        standaloneInputVal: this.value
      }
    },
    computed: {
      hasValue () {
        return !!(!!(this.standaloneInputVal) || !!(this.inputValue))
      },
      options () {
        return {
          disabled: this.inputViewMode,
          placeholder: this.inputPlaceholder,
          required: this.required,
          id: this.fieldId,
          class: ['mq-input', {'filled': this.hasValue}],
          type: this.as,
          name: this.field,
          rows: this.rows
        }
      }
    },
    watch: {
      standaloneInputVal(val) {
        this.$emit('input', val);
      }
    }
  }
</script>


<template>
  <div v-if='standalone' :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <textarea v-model='standaloneInputVal' v-bind='options'></textarea>
      
      <label :for='fieldId'>{{ inputLabel }}</label>
      
      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>
      
      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>
  
  <div v-else-if="form.record && field" :class="inputWrapperClass">
    <div :class="floatLabelClass">
      <textarea v-model='form.record[field]' v-bind='options'></textarea>
      
      <label :for='fieldId'>{{ inputLabel }}</label>
      
      <div v-show='!inputViewMode' v-if='hint' v-html='hint' class='input-hints'></div>
      
      <div v-if='showError' class='errors'>
        <p class='alert-color small'>{{ fieldError.join(' ') }}</p>
      </div>
    </div>
  </div>
</template>