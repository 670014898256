<script>
  import locales from '@moqomps/locales'
  import {rolePathPrefix, currentLocale} from '@/current_user'
  import payunityLoader from '@/lib/payunity_loader'

  window.wpwlOptions = {
    style: "plain",
    locale: currentLocale(),
    brandDetection: true,
    onReady:function(){
    }
  }

  export default {
    i18n: locales,

    props: {
      payunity_brands: {
        type: String,
        default: 'VISA MASTER'
      },
      payunity_js_widget_url: {
        type: String,
        requred: true
      },
      payunity_return_url: {
        type: String,
        requred: false,
        default: null
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      }
    },

    data () {
      return {
        payunityReturnURL: this.payunity_return_url || `${rolePathPrefix()}/payunity_payin_source/callback`
      }
    },

    mounted() {
      if (this.payunity_js_widget_url) {
        payunityLoader(this.payunity_js_widget_url)
      }
    },

    beforeDestroy() {
      // Unload the widget
      if (Object.keys(window.wpwl).length) {
        window.wpwl.unload()
        const scripts = document.getElementsByTagName('script')
        Array.from(scripts).forEach((script) => {
          if (/oppwa/.test(script.src)) {
            script.remove();
          }
        })
      }
    },

    computed: {
      payunityNotice () {
        return this.$t('payment.sources_create.payunity.disclaimer')
      },

      cancelButtonLabel () {
        return this.buttons_config?.cancel?.label ||
          this.$t('buttons.cancel')
      },

      cancelButtonClasses () {
        return this.buttons_config?.cancel?.classes ||
          'column text-center'
      }
    },

    methods: {
      cancel () {
        this.$emit('cancel')
      }
    }
  }
</script>


<template>
  <div>
    <form :action="payunityReturnURL" class="paymentWidgets" :data-brands="payunity_brands"></form>
    
    <p v-html="payunityNotice" class="gutter-bottom" ></p>
    
    <mq-input-row as='submit'>
      <div :class='cancelButtonClasses'>
        <mq-button class='payment_method_edit_card_cancel' as='ghost' size='small' color='gray' @click.native.prevent='cancel'>{{ cancelButtonLabel }}</mq-button>
      </div>
    </mq-input-row>
  </div>
</template>