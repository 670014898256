<script>
  import Resource from '@/resources/resources'
  import { rolePathPrefix } from '@/current_user'

  import PaymentSourceCreate from './organisms/payment_source_create'
  import PaymentSourcesList from './organisms/payment_sources_list'

  export default {
    props: {
      payin_sources: {
        type: Object,
        required: true
      },
      first_name: {
        type: String,
        required: false,
        default: ''
      },
      last_name: {
        type: String,
        required: false,
        default: ''
      },
      email: {
        type: String,
        required: false,
        default: ''
      },
      buttons_config: {
        type: Object,
        required: false,
        default: () => null
      },
      allow_submit: {
        type: Boolean,
        required: false,
        default: true
      },
      event_bus: {
        type: Object,
        required: false,
        default: () => null
      },
      payment_disclaimer: {
        type: String,
        required: true
      },
      disallow_sepa_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      allow_paypal_payments: {
        type: Boolean,
        required: false,
        default: false
      },
      is_loading: {
        type: Boolean,
        required: false,
        default: false
      },
      skip_payin_source_saving: {
        type: Boolean,
        required: false,
        default: false
      },
      create_with_type: {
        type: String,
        required: false,
        default: null
      }
    },
    components: {
      PaymentSourceCreate,
      PaymentSourcesList
    },
    data () {
      return {
        replace: false
      }
    },
    created () {
      this.replace = !this.payin_sources.payin_sources.length

      if(!!(this.event_bus)) {
        this.event_bus.$on('cancel-replacing-method', () => {
          this.replace = false
        })
      }
    },
    beforeDestroy () {
      if(!!(this.event_bus)) {
        this.event_bus.$off('cancel-replacing-method')
      }
    },
    computed: {
      elementType () {
        if (this.replace) {
          this.$emit('payment-method-replacing', {value: true})
          return 'payment-source-create'
        } else if(this.create_with_type)  {
          return 'payment-source-create'
        } else if (this.paymentConfigured || this.payinSourceAvailable) {
          this.$emit('payment-method-replacing', {value: false})
          return 'payment-sources-list'
        }

        return null
      },
      paymentConfigured () {
        return !!this && this.payinSourceAvailable
      },
      payinSourceAvailable () {
        if (!this.payin_sources) return
        return this.payin_sources.availability || !!this.payin_sources.payin_sources.length
      },
      ownerName () {
        return `${this.first_name} ${this.last_name}`
      },
      ownerEmail() {
        return this.email
      },
      options () {
        return {
          skip_payin_source_saving: this.skip_payin_source_saving,
          payin_sources: this.payin_sources,
          disallow_sepa_payments: this.disallow_sepa_payments,
          allow_paypal_payments: this.allow_paypal_payments,
          owner_name: this.ownerName,
          owner_email: this.ownerEmail,
          show_cancel: this.payinSourceAvailable,
          buttons_config: this.buttons_config,
          allow_submit: this.allow_submit,
          event_bus: this.event_bus,
          payment_disclaimer: this.payment_disclaimer,
          create_with_type: this.create_with_type
        }
      }
    },
    methods: {
      paymentMethodCreating (stripeElementEvent) {
        this.emitEvent('payment-method-creating', stripeElementEvent)
      },
      paymentMethodCreated () {
        this.emitEvent('payment-method-created')
      },
      paymentMethodReplacing () {
        this.replace = true
      },
      paymentMethodRemove(paymentMethodId) {
        this.emitEvent('payment-method-removing')
        let paymentMethod = Resource({ name: 'payin_sources', resourceId: paymentMethodId, path: `${rolePathPrefix()}/api/payin_sources`, skipFetching: true })
        paymentMethod.$on('error', (evt, error) => {
          this.$toast.add({ toastContainerId: 'footer', type: 'error', body: error.response.data.error_message, timeout: 5000 })
        })
        paymentMethod.destroy().then(record => {
          this.emitEvent('payment-method-removed')
        })
      },
      paymentMethodMakePrimary(paymentMethodId) {
        this.emitEvent('payment-method-switching-primary')
        let paymentMethod = Resource({ name: 'payin_sources', resourceId: paymentMethodId, path: `${rolePathPrefix()}/api/payin_sources`, skipFetching: true })
        paymentMethod.$on('error', (_event, error) => {
          this.$toast.add({ toastContainerId: 'footer', type: 'error', body: error.response.data.error_message, timeout: 5000 })
        })
        paymentMethod.isPrimary = true
        paymentMethod.update({ payload: { 'is_primary': '1' } }).then(_record => {
          this.emitEvent('payment-method-switched-primary')
          this.$toast.add({ toastContainerId: 'footer', type: 'success', body: this.$t('payment.sources_list.made_primary') })
        })
      },
      cancel () {
        this.$emit('cancel')
        this.replace = false
      },
      emitEvent(event, payload) {
        if (!!this.event_bus) {
          this.event_bus.$emit(event, payload)
        }

        this.$emit(event, payload)
      }
    },
    watch: {
      'payin_sources.payin_sources.length' (newValue, oldValue) {
        if (newValue > oldValue) this.replace = false
      }
    }
  }
</script>


<template>
  <div>
    <component :is='elementType'
               v-bind='options'
               @payment-method-replacing='paymentMethodReplacing'
               @remove='paymentMethodRemove'
               @payment-method-creating='paymentMethodCreating'
               @payment-method-created='paymentMethodCreated'
               @make-primary='paymentMethodMakePrimary'
               @tab-changed="$emit('tab-changed', $event)"
               @cancel='cancel'>
    </component>
  </div>
</template>
