import linkify from 'linkify-it';

export default {
  methods: {
    linkifyText(text) {
      const linkify = require('linkify-it')();

      const linkedText = linkify.match(text);
      if (linkedText) {
        linkedText.forEach((match) => {
          const href = match.schema === 'mailto:' ? `mailto:${match.url}` : match.url;
          const replacement = `<a href="${href}" target="_blank" rel="noopener noreferrer">${match.text}</a>`;
          text = text.replace(match.text, replacement);
        });
      }

      return text;
    }
  }
}
