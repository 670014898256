import setupVue from '@/setup_vue'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import MOQOmponents from '@moqomps'
import MqUtils from '@moqomps/mixins/MqUtils'
import SimpleModal from 'lib/modal'

import VueModal from 'vue-js-modal'

Vue.use(VueI18n)
Vue.use(MOQOmponents)
Vue.use(VueModal, { dynamic: true, injectModalsContainer: true })

Vue.mixin(SimpleModal)
Vue.mixin(MqUtils)

export default function(vueOptions, domLoadedCallback) {
  setupVue(vueOptions, ()=>{
    // make layout data accessible early so it can be used in any stage of the Vue lifecycle
    Vue.prototype.$layout = vueOptions.data.props.layout
    // window.$layout = vueOptions.data.props.layout

    if (domLoadedCallback) { domLoadedCallback() }
  })
}